<template>
    <div class="container view settings-categories">
        <div class="content-view settings-categories-view">
            <div class="action-header">
                <h1>{{ $t('Categories') }}</h1>
                <div class="actions">
                    <button type="button" class="btn btn-transparent" @click="$router.go(-1)">
                        {{ $t('Go Back') }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.829" height="21.451" viewBox="0 0 19.829 21.451">
                            <g>
                                <g>
                                    <path d="M6.118 1.032L2.8 4.349h8.477a8.6 8.6 0 0 1 8.552 8.551 8.653 8.653 0 0 1-8.551 8.551H1.917v-1.475h9.362a7.175 7.175 0 0 0 7.076-7.076 7.126 7.126 0 0 0-7.077-7.077H2.8L6.118 9.14l-1.032 1.032L0 5.086 5.086 0z" transform="translate(164) translate(-164)"/>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="settings-row">
                <router-link :to="{name: 'GeneralSettings'}" class="setting-wrapper">
                    <div class="setting-header">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32.637" height="49.013" viewBox="0 0 32.637 49.013">
                            <path d="M42.189 10.146h-1.631V2.42l-.816-.49-.4.171c-6.226 2.668-14.972 5.328-25.987 7.906a.889.889 0 0 0-.335.163 1.224 1.224 0 0 0-1.02 1.2v38.349a1.224 1.224 0 0 0 1.224 1.224h28.965a2.448 2.448 0 0 0 2.448-2.443V12.6a2.448 2.448 0 0 0-2.448-2.454zm-3.263-6.111v6.111h-19.3a149.958 149.958 0 0 0 19.3-6.111zm4.08 44.46a.816.816 0 0 1-.816.816H13.632V11.778h28.557a.816.816 0 0 1 .816.816z" transform="translate(-12 -1.93)"/>
                            <path fill="#1f40e5" d="M29.055 16a13.055 13.055 0 0 0 0 26.11 13.055 13.055 0 0 0 0-26.11zm8.739 5.712h-3.958a18.31 18.31 0 0 0-2.595-3.868 11.423 11.423 0 0 1 6.552 3.868zm-13.635 6.527a14.687 14.687 0 0 1 1.126-4.9h7.474a14.687 14.687 0 0 1 1.191 4.9zm9.791 1.632a14.687 14.687 0 0 1-1.126 4.9h-7.506a14.687 14.687 0 0 1-1.159-4.9zm-4.9-11.962a16.506 16.506 0 0 1 2.937 3.8h-5.869a16.506 16.506 0 0 1 2.937-3.8zm-2.187-.065a18.309 18.309 0 0 0-2.595 3.868h-3.952a11.423 11.423 0 0 1 6.552-3.868zm-7.678 5.5h4.382a16.229 16.229 0 0 0-1.044 4.9h-4.9a11.325 11.325 0 0 1 1.558-4.9zm.073 11.423h-.068a11.325 11.325 0 0 1-1.518-4.9h4.9a16.229 16.229 0 0 0 1.012 4.9zm1.058 1.633h3.957a18.31 18.31 0 0 0 2.595 3.868 11.423 11.423 0 0 1-6.552-3.868zm8.739 3.8a16.506 16.506 0 0 1-2.937-3.8h5.875a16.506 16.506 0 0 1-2.937 3.8zm2.187.065a18.31 18.31 0 0 0 2.594-3.865h3.957a11.423 11.423 0 0 1-6.552 3.868zm7.678-5.5h-4.382a16.229 16.229 0 0 0 1.044-4.9h4.9a11.325 11.325 0 0 1-1.558 4.9zm0-11.423a11.325 11.325 0 0 1 1.518 4.9h-4.856a16.229 16.229 0 0 0-1.012-4.9h4.275z" transform="translate(-12.736 -4.52)"/>
                            <path d="M35.607 52.632a.816.816 0 0 0 0-1.632h-9.791a.816.816 0 1 0 0 1.632z" transform="translate(-14.393 -10.962)"/>
                            <path d="M36.871 55H23.816a.816.816 0 1 0 0 1.632h13.055a.816.816 0 0 0 0-1.632z" transform="translate(-14.025 -11.699)"/>
                        </svg>
                        <h2>{{ $t('General Settings') }}</h2>
                    </div>
                    <div class="setting-content">
                        <p>{{ $t('In general settings you can change your profile information etc.') }}</p>
                        <div class="icon-right"></div>
                    </div>
                </router-link>
                <router-link :to="{name: 'Workflows'}" class="setting-wrapper">
                    <div class="setting-header">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.066" height="41.802" viewBox="0 0 37.066 41.802">
                            <g id="icon" transform="translate(-5.695 -0.462)">
                                <g id="Group_69" transform="translate(5.695 0.462)">
                                    <path fill="#1f40e5" id="Path_76" d="M65.166 31.663h-5.827a.972.972 0 1 0 0 1.943h4.856v7.066h-2.51l.632-.632a.972.972 0 0 0-1.375-1.374l-2.29 2.29a.971.971 0 0 0 0 1.374l2.29 2.292a.972.972 0 0 0 1.374-1.375l-.632-.633h3.481a.971.971 0 0 0 .972-.972v-9.007a.97.97 0 0 0-.971-.972z" class="cls-1" transform="translate(-33.995 -17.226)"/>
                                    <path id="Path_77" d="M21.153 58.827a.971.971 0 0 0-1.374 1.373l.631.632H17.9v-7.066h4.855a.972.972 0 1 0 0-1.943h-5.826a.971.971 0 0 0-.972.972V61.8a.971.971 0 0 0 .972.972h3.482l-.633.633a.972.972 0 0 0 1.375 1.374l2.29-2.291a.972.972 0 0 0 0-1.374z" transform="translate(-11.209 -28.058)"/>
                                    <path id="Path_78" d="M42.761 37.523V11.246a1 1 0 0 0-.022-.2c0-.006 0-.012-.006-.017a.97.97 0 0 0-.083-.222l-.031-.058a.976.976 0 0 0-.157-.2L32.229.732a.956.956 0 0 0-.208-.147L31.97.558a.969.969 0 0 0-.233-.079h-.026a.939.939 0 0 0-.154-.014H6.667a.971.971 0 0 0-.972.972v39.855a.971.971 0 0 0 .972.972h31.355a4.746 4.746 0 0 0 4.739-4.741zm-3.389-27.248h-4.53a2.314 2.314 0 0 1-2.313-2.31V3.712l4.03 3.865zM7.638 2.4h22.948v5.56a4.259 4.259 0 0 0 4.256 4.253h5.975v25.31a2.8 2.8 0 0 1-2.8 2.8H7.638z" transform="translate(-5.695 -0.462)"/>
                                    <path fill="#1f40e5" id="Path_79" d="M44.008 26.95H36.43a.973.973 0 0 0-.973.973v4.907a.973.973 0 0 0 .973.972h7.579a.972.972 0 0 0 .972-.972v-4.907a.973.973 0 0 0-.973-.973zm-.972 4.907H37.4v-2.963h5.636z" class="cls-1" transform="translate(-21.686 -14.694)"/>
                                    <path fill="#1f40e5" id="Path_80" d="M44.008 45.916H36.43a.972.972 0 0 0-.973.972v4.907a.972.972 0 0 0 .973.972h7.579a.971.971 0 0 0 .972-.972v-4.907a.972.972 0 0 0-.973-.972zm-.972 4.907H37.4v-2.964h5.636z" class="cls-1" transform="translate(-21.686 -24.884)"/>
                                    <path fill="#1f40e5" id="Path_81" d="M44.008 64.881H36.43a.972.972 0 0 0-.973.972v4.907a.972.972 0 0 0 .973.972h7.579a.971.971 0 0 0 .972-.972v-4.908a.971.971 0 0 0-.973-.971zm-.972 4.905H37.4v-2.962h5.636z" class="cls-1" transform="translate(-21.686 -35.074)"/>
                                    <path id="Path_82" d="M18.213 14.687h7.748a.972.972 0 0 0 0-1.943h-7.748a.972.972 0 1 0 0 1.943z" transform="translate(-11.898 -7.061)"/>
                                    <path id="Path_83" d="M18.213 22.528h2.916a.972.972 0 0 0 0-1.944h-2.916a.972.972 0 1 0 0 1.944z" transform="translate(-11.898 -11.273)"/>
                                </g>
                            </g>
                        </svg>

                        <h2>{{ $t('Workflows') }}</h2>
                    </div>
                    <div class="setting-content">
                        <p>{{ $t('In general settings you can change your profile information etc.') }}</p>
                        <div class="icon-right"></div>
                    </div>
                </router-link>
                <router-link :to="{name: 'Events'}" class="setting-wrapper">
                    <div class="setting-header">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.433" height="46.417" viewBox="0 0 37.433 46.417">
                            <g id="noun_Calendar_3944758" transform="translate(-7 -1)">
                                <path id="Path_85" d="M25.246 54.492A2.246 2.246 0 1 0 23 52.246a2.246 2.246 0 0 0 2.246 2.246zm0-2.995a.749.749 0 1 1-.749.749.749.749 0 0 1 .749-.746z" transform="translate(-4.021 -12.315)"/>
                                <path id="Path_86" d="M41.439 13.727h-1.633a2.246 2.246 0 0 0-4.222 0h-.135V5.492A4.492 4.492 0 0 0 30.957 1H11.492A4.492 4.492 0 0 0 7 5.492v37.433a4.492 4.492 0 0 0 4.492 4.492h19.465a4.492 4.492 0 0 0 4.492-4.492v-6.738h5.989a3 3 0 0 0 2.995-2.995v-16.47a3 3 0 0 0-2.994-2.995zm1.5 2.995v2.995H17.481v-2.995a1.5 1.5 0 0 1 1.5-1.5h1.5v.749a2.246 2.246 0 0 0 4.492 0v-.749h2.995v.749a2.246 2.246 0 1 0 4.492 0v-.749h2.995v.749a2.246 2.246 0 0 0 4.492 0v-.749h1.5a1.5 1.5 0 0 1 1.489 1.5zm-20.963-.749v-1.5a.749.749 0 1 1 1.5 0v1.5a.749.749 0 0 1-1.5 0zm7.487 0v-1.5a.749.749 0 1 1 1.5 0v1.5a.749.749 0 0 1-1.5 0zm7.487-1.5a.749.749 0 1 1 1.5 0v1.5a.749.749 0 1 1-1.5 0zm-2.998 28.452a3 3 0 0 1-2.995 2.995H11.492A3 3 0 0 1 8.5 42.925V5.492A3 3 0 0 1 11.492 2.5h19.465a3 3 0 0 1 2.995 2.995v8.235H32.32a2.246 2.246 0 0 0-4.222 0h-3.265a2.246 2.246 0 0 0-4.222 0h-1.632a3 3 0 0 0-2.995 2.995v16.468a3 3 0 0 0 2.995 2.995h11.978v5.989a.749.749 0 0 1-.749.749H12.241a.749.749 0 0 1-.749-.749V5.492h3.519l2.059 1.37a.749.749 0 0 0 .412.127h7.487a.749.749 0 0 0 .412-.127l2.059-1.37h3.519v5.989h1.5V5.492a1.5 1.5 0 0 0-1.5-1.5h-3.745a.749.749 0 0 0-.412.127l-2.059 1.37h-7.037l-2.059-1.37a.749.749 0 0 0-.412-.127h-3.743a1.5 1.5 0 0 0-1.5 1.5v36.685a2.246 2.246 0 0 0 2.246 2.246h17.971a2.246 2.246 0 0 0 2.246-2.246v-5.99h1.5zm7.487-8.235h-22.46a1.5 1.5 0 0 1-1.5-1.5V21.214h25.457v11.979a1.5 1.5 0 0 1-1.497 1.497z"/>
                                <path fill="#1f40e5" id="Path_87" d="M26.743 30h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H24.5V31.5H26z" class="cls-1" transform="translate(-4.021 -7.289)"/>
                                <path fill="#1f40e5" id="Path_88" d="M34.743 30h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H32.5V31.5H34z" class="cls-1" transform="translate(-6.032 -7.289)"/>
                                <path fill="#1f40e5" id="Path_89" d="M42.743 30h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H40.5V31.5H42z" class="cls-1" transform="translate(-8.043 -7.289)"/>
                                <path fill="#1f40e5" id="Path_90" d="M26.743 38h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H24.5V39.5H26z" class="cls-1" transform="translate(-4.021 -9.299)"/>
                                <path fill="#1f40e5" id="Path_91" d="M34.743 38h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H32.5V39.5H34z" class="cls-1" transform="translate(-6.032 -9.299)"/>
                                <path fill="#1f40e5" id="Path_92" d="M42.743 38h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H40.5V39.5H42z" class="cls-1" transform="translate(-8.043 -9.299)"/>
                                <path fill="#1f40e5" id="Path_93" d="M50.743 30h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H48.5V31.5H50z" class="cls-1" transform="translate(-10.053 -7.289)"/>
                                <path fill="#1f40e5" id="Path_94" d="M50.743 38h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H48.5V39.5H50z" class="cls-1" transform="translate(-10.053 -9.299)"/>
                            </g>
                        </svg>
                        <h2>{{ $t('Events') }}</h2>
                    </div>
                    <div class="setting-content">
                        <p>{{ $t('In general settings you can change your profile information etc.') }}</p>
                        <div class="icon-right"></div>
                    </div>
                </router-link>
            </div>
            <div class="settings-row">
                <router-link :to="{name: 'Industries'}" class="setting-wrapper">
                    <div class="setting-header">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32.637" height="49.013" viewBox="0 0 32.637 49.013">
                            <path d="M42.189 10.146h-1.631V2.42l-.816-.49-.4.171c-6.226 2.668-14.972 5.328-25.987 7.906a.889.889 0 0 0-.335.163 1.224 1.224 0 0 0-1.02 1.2v38.349a1.224 1.224 0 0 0 1.224 1.224h28.965a2.448 2.448 0 0 0 2.448-2.443V12.6a2.448 2.448 0 0 0-2.448-2.454zm-3.263-6.111v6.111h-19.3a149.958 149.958 0 0 0 19.3-6.111zm4.08 44.46a.816.816 0 0 1-.816.816H13.632V11.778h28.557a.816.816 0 0 1 .816.816z" transform="translate(-12 -1.93)"/>
                            <path fill="#1f40e5" d="M29.055 16a13.055 13.055 0 0 0 0 26.11 13.055 13.055 0 0 0 0-26.11zm8.739 5.712h-3.958a18.31 18.31 0 0 0-2.595-3.868 11.423 11.423 0 0 1 6.552 3.868zm-13.635 6.527a14.687 14.687 0 0 1 1.126-4.9h7.474a14.687 14.687 0 0 1 1.191 4.9zm9.791 1.632a14.687 14.687 0 0 1-1.126 4.9h-7.506a14.687 14.687 0 0 1-1.159-4.9zm-4.9-11.962a16.506 16.506 0 0 1 2.937 3.8h-5.869a16.506 16.506 0 0 1 2.937-3.8zm-2.187-.065a18.309 18.309 0 0 0-2.595 3.868h-3.952a11.423 11.423 0 0 1 6.552-3.868zm-7.678 5.5h4.382a16.229 16.229 0 0 0-1.044 4.9h-4.9a11.325 11.325 0 0 1 1.558-4.9zm.073 11.423h-.068a11.325 11.325 0 0 1-1.518-4.9h4.9a16.229 16.229 0 0 0 1.012 4.9zm1.058 1.633h3.957a18.31 18.31 0 0 0 2.595 3.868 11.423 11.423 0 0 1-6.552-3.868zm8.739 3.8a16.506 16.506 0 0 1-2.937-3.8h5.875a16.506 16.506 0 0 1-2.937 3.8zm2.187.065a18.31 18.31 0 0 0 2.594-3.865h3.957a11.423 11.423 0 0 1-6.552 3.868zm7.678-5.5h-4.382a16.229 16.229 0 0 0 1.044-4.9h4.9a11.325 11.325 0 0 1-1.558 4.9zm0-11.423a11.325 11.325 0 0 1 1.518 4.9h-4.856a16.229 16.229 0 0 0-1.012-4.9h4.275z" transform="translate(-12.736 -4.52)"/>
                            <path d="M35.607 52.632a.816.816 0 0 0 0-1.632h-9.791a.816.816 0 1 0 0 1.632z" transform="translate(-14.393 -10.962)"/>
                            <path d="M36.871 55H23.816a.816.816 0 1 0 0 1.632h13.055a.816.816 0 0 0 0-1.632z" transform="translate(-14.025 -11.699)"/>
                        </svg>
                        <h2>{{ $t('Industries') }}</h2>
                    </div>
                    <div class="setting-content">
                        <p>{{ $t('In general settings you can change your profile information etc.') }}</p>
                        <div class="icon-right"></div>
                    </div>
                </router-link>
                <router-link :to="{name: 'GeneralSettings'}" class="setting-wrapper">
                    <div class="setting-header">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.066" height="41.802" viewBox="0 0 37.066 41.802">
                            <g id="icon" transform="translate(-5.695 -0.462)">
                                <g id="Group_69" transform="translate(5.695 0.462)">
                                    <path fill="#1f40e5" id="Path_76" d="M65.166 31.663h-5.827a.972.972 0 1 0 0 1.943h4.856v7.066h-2.51l.632-.632a.972.972 0 0 0-1.375-1.374l-2.29 2.29a.971.971 0 0 0 0 1.374l2.29 2.292a.972.972 0 0 0 1.374-1.375l-.632-.633h3.481a.971.971 0 0 0 .972-.972v-9.007a.97.97 0 0 0-.971-.972z" class="cls-1" transform="translate(-33.995 -17.226)"/>
                                    <path id="Path_77" d="M21.153 58.827a.971.971 0 0 0-1.374 1.373l.631.632H17.9v-7.066h4.855a.972.972 0 1 0 0-1.943h-5.826a.971.971 0 0 0-.972.972V61.8a.971.971 0 0 0 .972.972h3.482l-.633.633a.972.972 0 0 0 1.375 1.374l2.29-2.291a.972.972 0 0 0 0-1.374z" transform="translate(-11.209 -28.058)"/>
                                    <path id="Path_78" d="M42.761 37.523V11.246a1 1 0 0 0-.022-.2c0-.006 0-.012-.006-.017a.97.97 0 0 0-.083-.222l-.031-.058a.976.976 0 0 0-.157-.2L32.229.732a.956.956 0 0 0-.208-.147L31.97.558a.969.969 0 0 0-.233-.079h-.026a.939.939 0 0 0-.154-.014H6.667a.971.971 0 0 0-.972.972v39.855a.971.971 0 0 0 .972.972h31.355a4.746 4.746 0 0 0 4.739-4.741zm-3.389-27.248h-4.53a2.314 2.314 0 0 1-2.313-2.31V3.712l4.03 3.865zM7.638 2.4h22.948v5.56a4.259 4.259 0 0 0 4.256 4.253h5.975v25.31a2.8 2.8 0 0 1-2.8 2.8H7.638z" transform="translate(-5.695 -0.462)"/>
                                    <path fill="#1f40e5" id="Path_79" d="M44.008 26.95H36.43a.973.973 0 0 0-.973.973v4.907a.973.973 0 0 0 .973.972h7.579a.972.972 0 0 0 .972-.972v-4.907a.973.973 0 0 0-.973-.973zm-.972 4.907H37.4v-2.963h5.636z" class="cls-1" transform="translate(-21.686 -14.694)"/>
                                    <path fill="#1f40e5" id="Path_80" d="M44.008 45.916H36.43a.972.972 0 0 0-.973.972v4.907a.972.972 0 0 0 .973.972h7.579a.971.971 0 0 0 .972-.972v-4.907a.972.972 0 0 0-.973-.972zm-.972 4.907H37.4v-2.964h5.636z" class="cls-1" transform="translate(-21.686 -24.884)"/>
                                    <path fill="#1f40e5" id="Path_81" d="M44.008 64.881H36.43a.972.972 0 0 0-.973.972v4.907a.972.972 0 0 0 .973.972h7.579a.971.971 0 0 0 .972-.972v-4.908a.971.971 0 0 0-.973-.971zm-.972 4.905H37.4v-2.962h5.636z" class="cls-1" transform="translate(-21.686 -35.074)"/>
                                    <path id="Path_82" d="M18.213 14.687h7.748a.972.972 0 0 0 0-1.943h-7.748a.972.972 0 1 0 0 1.943z" transform="translate(-11.898 -7.061)"/>
                                    <path id="Path_83" d="M18.213 22.528h2.916a.972.972 0 0 0 0-1.944h-2.916a.972.972 0 1 0 0 1.944z" transform="translate(-11.898 -11.273)"/>
                                </g>
                            </g>
                        </svg>
                        <h2>{{ $t('User Terms & Policy') }}</h2>
                    </div>
                    <div class="setting-content">
                        <p>{{ $t('In general settings you can change your profile information etc.') }}</p>
                        <div class="icon-right"></div>
                    </div>
                </router-link>
                <router-link :to="{name: 'GeneralSettings'}" class="setting-wrapper">
                    <div class="setting-header">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.433" height="46.417" viewBox="0 0 37.433 46.417">
                            <g id="noun_Calendar_3944758" transform="translate(-7 -1)">
                                <path id="Path_85" d="M25.246 54.492A2.246 2.246 0 1 0 23 52.246a2.246 2.246 0 0 0 2.246 2.246zm0-2.995a.749.749 0 1 1-.749.749.749.749 0 0 1 .749-.746z" transform="translate(-4.021 -12.315)"/>
                                <path id="Path_86" d="M41.439 13.727h-1.633a2.246 2.246 0 0 0-4.222 0h-.135V5.492A4.492 4.492 0 0 0 30.957 1H11.492A4.492 4.492 0 0 0 7 5.492v37.433a4.492 4.492 0 0 0 4.492 4.492h19.465a4.492 4.492 0 0 0 4.492-4.492v-6.738h5.989a3 3 0 0 0 2.995-2.995v-16.47a3 3 0 0 0-2.994-2.995zm1.5 2.995v2.995H17.481v-2.995a1.5 1.5 0 0 1 1.5-1.5h1.5v.749a2.246 2.246 0 0 0 4.492 0v-.749h2.995v.749a2.246 2.246 0 1 0 4.492 0v-.749h2.995v.749a2.246 2.246 0 0 0 4.492 0v-.749h1.5a1.5 1.5 0 0 1 1.489 1.5zm-20.963-.749v-1.5a.749.749 0 1 1 1.5 0v1.5a.749.749 0 0 1-1.5 0zm7.487 0v-1.5a.749.749 0 1 1 1.5 0v1.5a.749.749 0 0 1-1.5 0zm7.487-1.5a.749.749 0 1 1 1.5 0v1.5a.749.749 0 1 1-1.5 0zm-2.998 28.452a3 3 0 0 1-2.995 2.995H11.492A3 3 0 0 1 8.5 42.925V5.492A3 3 0 0 1 11.492 2.5h19.465a3 3 0 0 1 2.995 2.995v8.235H32.32a2.246 2.246 0 0 0-4.222 0h-3.265a2.246 2.246 0 0 0-4.222 0h-1.632a3 3 0 0 0-2.995 2.995v16.468a3 3 0 0 0 2.995 2.995h11.978v5.989a.749.749 0 0 1-.749.749H12.241a.749.749 0 0 1-.749-.749V5.492h3.519l2.059 1.37a.749.749 0 0 0 .412.127h7.487a.749.749 0 0 0 .412-.127l2.059-1.37h3.519v5.989h1.5V5.492a1.5 1.5 0 0 0-1.5-1.5h-3.745a.749.749 0 0 0-.412.127l-2.059 1.37h-7.037l-2.059-1.37a.749.749 0 0 0-.412-.127h-3.743a1.5 1.5 0 0 0-1.5 1.5v36.685a2.246 2.246 0 0 0 2.246 2.246h17.971a2.246 2.246 0 0 0 2.246-2.246v-5.99h1.5zm7.487-8.235h-22.46a1.5 1.5 0 0 1-1.5-1.5V21.214h25.457v11.979a1.5 1.5 0 0 1-1.497 1.497z"/>
                                <path fill="#1f40e5" id="Path_87" d="M26.743 30h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H24.5V31.5H26z" class="cls-1" transform="translate(-4.021 -7.289)"/>
                                <path fill="#1f40e5" id="Path_88" d="M34.743 30h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H32.5V31.5H34z" class="cls-1" transform="translate(-6.032 -7.289)"/>
                                <path fill="#1f40e5" id="Path_89" d="M42.743 30h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H40.5V31.5H42z" class="cls-1" transform="translate(-8.043 -7.289)"/>
                                <path fill="#1f40e5" id="Path_90" d="M26.743 38h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H24.5V39.5H26z" class="cls-1" transform="translate(-4.021 -9.299)"/>
                                <path fill="#1f40e5" id="Path_91" d="M34.743 38h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H32.5V39.5H34z" class="cls-1" transform="translate(-6.032 -9.299)"/>
                                <path fill="#1f40e5" id="Path_92" d="M42.743 38h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H40.5V39.5H42z" class="cls-1" transform="translate(-8.043 -9.299)"/>
                                <path fill="#1f40e5" id="Path_93" d="M50.743 30h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H48.5V31.5H50z" class="cls-1" transform="translate(-10.053 -7.289)"/>
                                <path fill="#1f40e5" id="Path_94" d="M50.743 38h-2.994a.749.749 0 0 0-.749.749v2.995a.749.749 0 0 0 .749.749h2.995a.749.749 0 0 0 .749-.749v-2.995a.749.749 0 0 0-.75-.749zm-.749 2.995H48.5V39.5H50z" class="cls-1" transform="translate(-10.053 -9.299)"/>
                            </g>
                        </svg>
                        <h2>{{ $t('Other') }}</h2>
                    </div>
                    <div class="setting-content">
                        <p>{{ $t('In general settings you can change your profile information etc.') }}</p>
                        <div class="icon-right"></div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>